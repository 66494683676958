
















import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "BackBar"
})
export default class BackBar extends Vue {
  callback() {
    this.$emit("callback")
  }
}
