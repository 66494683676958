import { Component, Vue } from "vue-property-decorator"

@Component
export default class InputMixin extends Vue {
  get responsiveTextFieldHeight(): string {
    const bp = this.$vuetify.breakpoint
    let height = 0
    if (bp.lgAndDown) {
      height = 60
    } else {
      height = 80
    }
    return `${height}px`
  }

  private get fileButtonWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.lgAndDown) {
      return "114px"
    }
    return "200px"
  }

  private get fileButtonHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.lgAndDown) {
      return 54
    }
    return 74
  }
}
