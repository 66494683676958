













































































































import { Component, Watch } from "vue-property-decorator"
import ViewWrapper from "@/views/ViewWrapper.vue"
import CareerDetail from "@/types/Career/CareerDetail"
import CareerApi from "@/api/Career/CareerApi"
import { deserialize } from "typescript-json-serializer"
import CareerFooterComponent from "@/components/Career/Main/CareerFooter.vue"
import BackBar from "@/components/BackBar.vue"
import CareerJob from "@/types/Career/CareerJob"
import _ from "lodash"
import { mixins } from "vue-class-component"
import InputMixin from "@/types/InputMixin"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "CareerApplyPage",
  components: { BackBar, CareerFooterComponent, ViewWrapper }
})
export default class CareerApplyPage extends mixins(InputMixin, BreakpointMixin) {
  private submitting = false

  private acceptingFileTypes = [
    "image/*",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/pdf"
  ]

  private get submitButtonWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.mdAndDown) {
      return "310px"
    }
    return "392px"
  }

  private detail: CareerDetail = new CareerDetail()

  private job: CareerJob = new CareerJob()

  private rules = {
    required: (value: string) => !!value || "Required.",
    email: (value: string) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || "Invalid e-mail."
    }
  }

  // eslint-disable-next-line class-methods-use-this
  toggleFileInput() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const elm = document.getElementById("fileInput")!
    elm.click()
  }

  submit() {
    this.submitting = true
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    CareerApi.postCareerJob(this.detail.id!, this.job)
      .then(() => {
        // eslint-disable-next-line no-alert
        alert("ส่งข้อความสำเร็จ")
        this.job = new CareerJob()
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert("ส่งข้อความไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
      })
      .finally(() => {
        this.submitting = false
      })
  }

  get formCompleted(): boolean {
    const { name, tel, email, file } = this.job
    return !!name && !!tel && !!email && !!file
  }

  get acceptFileTypeString(): string {
    return _.join(this.acceptingFileTypes, ",")
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    const id: number = (this.$route.query.id as unknown as number) || -1
    if (id === -1) {
      this.$router.push({ name: "CareerMainPage" })
    }
    this.fetchCareerDetail(id)
  }

  mounted() {
    this.fetchData()
  }

  fetchCareerDetail(id: number) {
    CareerApi.getCareerDetail(id).then(({ data }) => {
      this.detail = deserialize<CareerDetail>(data, CareerDetail)
    })
  }

  private formTemplate = [
    {
      label: "ชื่อ-นามสกุล",
      value: "name",
      type: "text",
      rules: [this.rules.required]
    },
    {
      label: "เบอร์โทรศัพท์",
      value: "tel",
      type: "text",
      rules: [this.rules.required]
    },
    {
      label: "อีเมล",
      value: "email",
      type: "text",
      rules: [this.rules.required, this.rules.email]
    },
    {
      label: "แนบไฟล์ประวัติ",
      value: "file",
      type: "file"
    }
  ]
}
