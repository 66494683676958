import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class CareerDetail {
  @JsonProperty("career_id") id?: number

  @JsonProperty() position?: string

  @JsonProperty() location?: string

  @JsonProperty() description?: string

  @JsonProperty("created_date") createdDate?: string

  @JsonProperty() status?: string

  @JsonProperty() lang?: string
}
